import { Directive, inject, Injector, OnDestroy, OnInit, Optional, Self } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { SelectService } from "@shared/components/selects/select.service";
import { Dropdown, DropdownChangeEvent } from "primeng/dropdown";




@Directive({
    selector: '[institutionSelect]',
    standalone: true
})
export class InstitutionSelectDirective implements OnInit, OnDestroy {
    private selectService = inject(SelectService);
    private control: FormControl | null = null;
    constructor(@Self() private readonly primeDropdown: Dropdown, private injector: Injector,
        @Optional() private ngControl: NgControl) {

        if (this.ngControl) {
            this.control = this.ngControl.control as FormControl;
        }

        this.primeDropdown.placeholder = 'Select Institution';
        this.primeDropdown.id = 'institution';
        this.GetInstitutions();


    }

    GetInstitutions() {
        this.selectService.getInstitutionsForSelect()?.subscribe({
            next: (data) => {
                this.primeDropdown.options = [...data];
                if (this.primeDropdown.options.length > 0) {
                    this.primeDropdown.writeValue(0);
                    this.control?.setValue(0);
                    this.primeDropdown.onChange.emit(data[0] as DropdownChangeEvent)
                }
            }
        });
    }

    ngOnDestroy(): void {

    }
    ngOnInit(): void {

    }
}