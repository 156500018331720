<p-dropdown
  [options]="schoolPeriods"
  [(ngModel)]="selectedSchoolPeriod"
  optionLabel="name"
  [disabled]="disabled"
  styleClass="{{ styleClass }}"
  [loading]="loading"
  (onChange)="onSchoolPeriodChange($event.value)"
  placeholder="Select a School Period" 
  [ngClass]="{ 'borderless': !modal }"/>
