import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { GetStudentsScoresRequest } from "./model/request/get-students-scores-request";
import { StateService } from "@shared/service/state.service";
import { StudentResponse } from "./model/response/student-response";
import { StudentsResponse } from "./model/response/students-response";
import { map } from "rxjs";
import { StudentResultResponse } from "./student/models/student-result-response";
import { controllers } from "@shared/utils/controllers";
import { StudentsForSearch } from "./model/response/students-for-search";


@Injectable({
    providedIn: 'root'
})
export class StudentsService {
    private _httpClient = inject(HttpClient);
    private stateService = inject(StateService);
    private controller = controllers.Dashboard.Students;

    GetStudentsScore(filters: GetStudentsScoresRequest) {
        return this._httpClient.get<StudentsResponse[]>(`${this.controller}/GetStudents?SelDiagnosticId=${filters.selDiagnosticId}&GroupId=${filters.groupId}`).pipe(
            map((data) => {
                return data.map((student) => {
                    student.check = false;
                    return student;
                });
            })
        );
    }
    GetStudentForSearch() {
        return this._httpClient.get<StudentsForSearch[]>(`${this.controller}/GetStudentsForSearch`);
    }

    GetStudentById(studentId: number) {
        const schoolPeriodId = this.stateService.schoolPeriodId;
        return this._httpClient.get<StudentResponse>(`${this.controller}/GetStudentById?StudentId=${studentId}&SchoolPeriodId=${schoolPeriodId}`);
    }

    UpdateStudent(student: StudentResponse) {
        const schoolPeriodId = this.stateService.schoolPeriodId;
        return this._httpClient.put(`${this.controller}/UpdateStudent?SchoolPeriodId=${schoolPeriodId}`, student);
    }

    DownloadExcelStudents() {
        const schoolPeriodId = this.stateService.schoolPeriodId;
        return this._httpClient.get(`${this.controller}/GetStudentsExcel?SchoolPeriodId=${schoolPeriodId}`, { responseType: 'blob' });
    }

    DownloadExcelInterventionStudents() {
        const schoolPeriodId = this.stateService.schoolPeriodId;
        return this._httpClient.get(`${this.controller}/GetStudentsInterventionsExcel`, { responseType: 'blob' });
    }

    SendEmailPasswordToStudents(studentsId: number[]) {
        return this._httpClient.post(`${this.controller}/SendEmailPassword`, studentsId);
    }

    GetStudentResults(studentId: number, selDiagnosticId: number | null) {
        if (selDiagnosticId == null) {
            return this._httpClient.get<StudentResultResponse>(`${this.controller}/GetStudentResults?StudentId=${studentId}`);

        }
        return this._httpClient.get<StudentResultResponse>(`${this.controller}/GetStudentResults?StudentId=${studentId}&SelDiagnosticId=${selDiagnosticId}`)

    }

}