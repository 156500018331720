

export const controllers = {
    Auth: 'Auth',
    AuthStudent: 'AuthStudent',
    Dashboard: {
        Dashboard: 'SelDashboard',
        ReportPdf: 'ReportPdf',
        SelDiagnostic: 'SelDiagnostic',
        SelGroups: 'SelGroups',
        Results: 'Results',
        Students: 'Students',
        SelSummary: 'SelSummary',
        Teachers: 'Teachers',
        SelInterventions: 'SelInterventions',
        Maria: 'Maria',
        SelEducators: 'SelEducators',
        Documents: 'SelDocument',
        SkillSummary: 'SkillSummary',
        StylearnSummary: 'StylearnSummary',
    },
    Institution: {
        // SelDiagnostic: 'InstitutionDiagnostic',
        SelSummary: 'InstitutionSummary',
        Results: 'InstitutionResults',
        Schools: 'InstitutionSchools',
    },
    Configuration: {
        User: 'User',
        Institution: 'Institution',
    },
    Student: {
        SelStudent: 'StudentApi/SelStudent',
        SkillStudent: 'StudentApi/SkillStudent',
        StylearnStudent: 'StudentApi/StylearnStudent',
        SocialinkStudent: 'StudentApi/SocialinkStudent',
    },
    Skills: {
        SkillDiagnostic: 'SkillDiagnostic',
        ResultsSkills: 'ResultsSkills',
        SkillGroups: 'SkillGroups',
        StudentsSkill: 'StudentsSkill',
    },
    Stylearn: {
        StylearnDiagnostic: 'StylearnDiagnostic',
        ResultsStylearn: 'ResultsStylearn',
        StudentsStylearn: 'StudentsStylearn',
        StylearnGroups: 'StylearnGroups',
    },
    Socialink: {
        SocialinkDiagnostic: 'SocialinkDiagnostic',
        ResultsSocialink: 'ResultsSocialink',
        SocialinkGroups: 'SocialinkGroups',
        StudentsSocialink: 'StudentsSocialink',
    },
}
