import { inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { StateService } from '@shared/service/state.service';
import { DropdownModule } from 'primeng/dropdown';
interface Language {
  name: string;
  code: string;
}

@Component({
  selector: 'app-language',
  standalone: true,
  imports: [DropdownModule, FormsModule],
  templateUrl: './language.component.html',
  styleUrl: './language.component.scss'
})
export class LanguageComponent implements OnInit {
  languages: Language[] = [];
  selectedLanguage: Language | undefined;
  private translocoService = inject(TranslocoService);
  private stateService = inject(StateService);
  constructor() {


  }
  ngOnInit(): void {
    this.languages = [
      { name: 'English', code: 'en-US' },
      { name: 'Español', code: 'es-MX' }
    ];
    const language = this.stateService.getLanguage();
    this.selectedLanguage = this.languages.find((lang) => lang.code === language);
    this.translocoService.setActiveLang(language);
  }
  switchLanguage(lang: Language) {
    this.translocoService.setActiveLang(lang.code);
    this.stateService.setLanguage(lang.code);
    window.location.reload();
  }
}
