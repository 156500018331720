import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '@environments/environment'

export const baseInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {


    if (req.url.includes('i18n')) {
        return next(req);
    }

    // if (req.url.includes('maria-api')) {
    //     return next(req);
    // }

    req = req.clone({
        url: `${environment.baseUrl}/v1/${req.url}`
    });
    return next(req);
}