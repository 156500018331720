

import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { isFunction, TranslocoService } from "@jsverse/transloco";
import { MessageToastService } from "@shared/service/message-toast.service";
import { Observable, catchError, throwError } from "rxjs";

export const errorInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const messageToastService = inject(MessageToastService);
    const translocoService = inject(TranslocoService);
    const router = inject(Router);


    return next(req).pipe(
        catchError((err: HttpErrorResponse) => {

            if (err instanceof HttpErrorResponse) {

                if (err.error?.errors == null) {
                    if (err.status == 401) {
                        const title = translocoService.translate('error.unauthorized.title');
                        const description = translocoService.translate('error.unauthorized.description');
                        messageToastService.showError(title, description);
                        router.navigate(['auth']);
                    }
                    return throwError(() => err);
                }

                const error: errorinterface = err.error?.errors[0];
                // Handle HTTP errors
                switch (err.status) {
                    case 400:
                        messageToastService.showError(error.title, error.description);
                        break;
                    case 401:
                        if (error != null) {
                            messageToastService.showError(error.title, error.description);
                        } else {
                            const title = translocoService.translate('error.unauthorized.title');
                            const description = translocoService.translate('error.unauthorized.description');
                            messageToastService.showError(title, description);
                            router.navigate(['auth']);
                        }

                        break;
                    case 403:
                        messageToastService.showError(error.title, error.description);
                        router.navigate(['access']);
                        break;
                    case 404:
                        messageToastService.showError(error.title, error.description);
                        break;
                    case 409:
                        messageToastService.showWarning(error.title, error.description);
                        break;
                    case 500:
                        messageToastService.showError(error.title, error.description);
                        break;
                    default:
                        messageToastService.showError(error.title, error.description);
                        break;
                }

            } else {
                // Handle non-HTTP errors

            }

            // Re-throw the error to propagate it further
            return throwError(() => err);
        })
    );;
};


const handleError = (messageToastService: MessageToastService, error: errorinterface) => {
    // Handle the error here



}

interface errorinterface {
    title: string;
    description: string;
    internalCode: string;
}