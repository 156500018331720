<ng-container *transloco="let t; prefix: 'menu'">
  <aside class="left-sidebar layout-sidebar" [ngClass]="{ active: openSideBar }">
    <div class="navbar-logo" style="text-align: center; width: 100%; height: 120px" [ngClass]="{ hidden: !selMenu }">
      <a class="navbar-brand" style="padding-top: 10px !important; margin-right: 0px !important">
        <!-- Logo icon -->
        <b>
          <img
            [src]="getLogoSrc(true)"
            [alt]="getLogoAlt()"
            class="dark-logo"
            [ngClass]="{ flex: openSideBar, hidden: !openSideBar }"
            style="height: 65px; width: auto"
          />

          <!-- Imagen para cuando el menú está cerrado -->
          <img
            [src]="getLogoSrc(false)"
            [alt]="getLogoAlt()"
            class="dark-logo"
            [ngClass]="{ hidden: openSideBar, flex: !openSideBar }"
            style="height: 29px; width: auto"
          />
        </b>
        <!--End Logo icon -->
      </a>
    </div>

    <div class="navbar-logo" style="text-align: center; width: 100%; height: 140px" [ngClass]="{ hidden: selMenu }">
      <a class="navbar-brand" style="padding-top: 10px !important; margin-right: 0px !important">
        <!-- Logo icon -->
        <b>
          <img
            [ngClass]="{ flex: openSideBar, hidden: !openSideBar }"
            src="assets/images/Edify Logo.png"
            height="65"
            alt="homepage"
            class="dark-logo"
          />
          <img
            [ngClass]="{ hidden: openSideBar, flex: !openSideBar }"
            src="assets/images/fft-hover.png"
            width="29"
            alt="homepage"
            class="dark-logo"
          />
        </b>
        <!--End Logo icon -->
      </a>
    </div>

    <div style="position: absolute; right: -20px; top: 70px">
      <span (click)="openMenu()" class="btn-sidebar">
        <img src="./assets/new-icons/icon-menu.svg" [ngClass]="{ hidden: openSideBar, flex: !openSideBar }" />

        <img src="./assets/new-icons/icon-menu2.svg" [ngClass]="{ flex: openSideBar, hidden: !openSideBar }" />
      </span>
    </div>

    <div #menuContainer class="layout-menu-container" [ngClass]="{ closeMenu: !openSideBar }">
      <app-menu></app-menu>
    </div>

    <div class="navbar-footer sidebar-nav" style="text-align: center; background-color: transparent !important">
      <ng-container>
        <div class="itemsSidebar dropright keep-open">
          <div class="dropdown-toggle">
            <div
              (click)="overlayPanel.toggle($event)"
              [ngClass]="{ hidden: !selMenu }"
              class="w-full navbar-brand cursor-pointer"
              style="padding-top: 25px !important; margin-right: 0px !important"
            >
              <!-- Logo icon -->
              <div class="row flex apps-Icon w-full justify-content-center" style="align-items: center">
                <i class="layout-menuitem-icon iconApps"></i>

                <span [ngClass]="{ openCambiar: openSideBar === true }" class="font-16 pl-2 spanCambiar">
                  {{ t('open-apps') }}</span
                >
              </div>
              <!--End Logo icon -->
            </div>

            <p-overlayPanel #overlayPanel>
              <ng-template pTemplate="content">
                <div class="grid p-2" style="width: 300px; row-gap: 10px" (click)="onContentClick()">
                  @for (module of modules; track $index) {
                    <div
                      class="col-4 text-center justify-content-center p-2"
                      [ngClass]="module.active ? 'divApps' : 'disabled'"
                      (click)="navigateToModule(module.moduleId)"
                      style="display: grid"
                    >
                      <div
                        style="height: 50px; width: 50px"
                        class="align-items-center mb-3 justify-content-center flex"
                      >
                        <img
                          [src]="'assets/images/modules-images/' + module.moduleId + '.png'"
                          alt="homepage"
                          class="dark-logo w-full"
                        />
                      </div>
                      {{ module.name }}
                    </div>
                  }
                </div>
              </ng-template>
            </p-overlayPanel>

            <div
              (click)="changeSEL()"
              [ngClass]="{ hidden: selMenu }"
              class="navbar-brand cursor-pointer"
              style="padding-top: 25px !important; margin-right: 0px !important"
            >
              <!-- Logo icon -->
              <div class="row flex" style="align-items: center">
                <span [ngClass]="{ openCambiar: openSideBar === true }" class="font-16 pr-2 spanCambiar">
                  <img src="assets/new-icons/logout.svg" style="padding-right: 5px" />Cambiar a SEL</span
                >

                <img
                  src="assets/images/SEL.png"
                  alt="edify"
                  class="dark-logo"
                  [ngClass]="{ 'w-22': openSideBar, 'w-30': !openSideBar }"
                />
              </div>
              <!--End Logo icon -->
            </div>
          </div>
        </div>
      </ng-container>

      <hr style="width: 80%; border-top: 2px solid rgba(0, 0, 0, 0.1)" class="mx-auto" />

      <ng-container>
        <div class="itemsSidebar dropright keep-open">
          <div class="dropdown-toggle">
            <a class="navbar-brand" style="margin-right: 0px !important">
              <!-- Logo icon -->
              <b>
                <img
                  src="./assets/images/logo-mini.png"
                  width="28"
                  alt="homepage"
                  class="dark-logo"
                  [ngClass]="{ hidden: openSideBar, flex: !openSideBar }"
                  [routerLink]="['/app']"
                  style="cursor: pointer"
                />
                <img
                  src="./assets/images/logo-new.png"
                  [ngClass]="{ flex: openSideBar, hidden: !openSideBar }"
                  class="dark-logo"
                  [routerLink]="['/app']"
                  style="height: 40px; cursor: pointer"
                />
              </b>
              <!--End Logo icon -->
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </aside>
</ng-container>
