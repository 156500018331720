<p-sidebar
  [(visible)]="visible"
  position="right"
  [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
  styleClass="layout-profile-sidebar w-full sm:w-25rem"
>
  <ng-container *transloco="let t; prefix: 'profile'">
    <div class="flex flex-column mx-auto md:mx-0">
      <span class="mb-2 font-semibold">{{ t('hi') }}, {{ authService.getSessionUser().name }}! </span>
      <span class="text-color-secondary font-medium mb-5"> {{ authService.getSessionUser().schoolName }} </span>

      <ul class="list-none m-0 p-0">
        <li>
          <a
            (click)="singOut()"
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
          >
            <span>
              <i class="pi pi-power-off text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">{{ t('sing-out') }} </span>
            </div>
          </a>
        </li>
        <li>
          <div
            [hidden]="!permissionsUser.canActivatePrivacy"
            class="switch p-3 w-full border-1 surface-border mb-3"
            style="font-size: 14px; border-radius: 14px !important"
          >
            <label class="font14 fontBold mr-3 cursor-pointer" style="justify-content: center">
              {{ t('privacy') }}
              <input [(ngModel)]="isPrivacy" class="cursor-pointer" (change)="togglePrivacy()" type="checkbox" />
              <span class="lever switch-col-pink privacidadCheck ml-3" style="margin-top: 5px !important"></span>
            </label>
          </div>
        </li>
        <li>
          <div
            [hidden]="permissionsUser.canActivatePrivacy"
            class="switch p-3 w-full border-1 surface-border mb-3"
            style="font-size: 14px; border-radius: 14px !important"
          >
            <label style="justify-content: center" class="font14 fontBold mr-3">
              {{ t('privacy') }}
              <input
                type="text"
                [value]="isPrivacy ? t('on') : t('off')"
                class="form-control ml-3 inputPrivacidad"
                id="seguridad-check"
                disabled
              />
            </label>
          </div>
        </li>
        <li>
          <div
            [hidden]="!permissionsUser.canActivateInstitutionalView"
            class="switch p-3 w-full border-1 surface-border mb-3"
            style="font-size: 14px; border-radius: 14px !important"
          >
            <label class="font14 fontBold mr-3 cursor-pointer" style="justify-content: center">
              {{ t('institutional-view') }}
              <input
                [(ngModel)]="isInstitutionalView"
                class="cursor-pointer"
                (change)="toggleInstitutionalView()"
                type="checkbox"
              />
              <span class="lever switch-col-pink privacidadCheck ml-3" style="margin-top: 5px !important"></span>
            </label>
          </div>
        </li>
        <li>
          <div class="w-full">
            <app-language></app-language>
          </div>
        </li>
      </ul>
    </div>
  </ng-container>
</p-sidebar>
