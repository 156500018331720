import { Component, Output, inject, OnChanges, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { SchoolPeriodTableResponse } from '@modules/configuration/pages/school-periods/models/schoolPeriod-table-response';
import { SchoolPeriodService } from '@modules/configuration/pages/school-periods/school-period.service';
import { SchoolService } from '@modules/configuration/pages/schools/school.service';
import { FormImports } from '@shared/shared.import';
import { DropdownModule } from 'primeng/dropdown';
import { StateService } from '../../../service/state.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-school-period-select',
  standalone: true,
  imports: [DropdownModule, ...FormImports, TranslocoDirective, NgClass],
  templateUrl: './school-period-select.component.html',
  styleUrl: './school-period-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SchoolPeriodSelectComponent,
      multi: true
    }
  ]
})
export class SchoolPeriodSelectComponent implements ControlValueAccessor {
  @Output() OnChanges = new EventEmitter<number>();
  @Input() styleClass: string = '';
  @Input() modal: boolean = false;

  private stateService = inject(StateService);
  schoolPeriods: SchoolPeriodTableResponse[] = [];
  selectedSchoolPeriod: SchoolPeriodTableResponse | undefined;
  private schoolService = inject(SchoolService);
  private schoolPeriodService = inject(SchoolPeriodService);
  loading: boolean = false;
  disabled: boolean = false;


  constructor() {
    this.GetSchoolPeriods();
  }
  onSchoolPeriodChange(schoolPeriod: SchoolPeriodTableResponse) {
    this.selectedSchoolPeriod = schoolPeriod;
    this._onChange(schoolPeriod.id);
    this.OnChanges.emit(schoolPeriod.id);
    this._onTouched();
  }
  //#region ControlValueAccessor
  private _onChange: Function = (_value: number) => { };
  private _onTouched: Function = (_value: number) => { };

  writeValue(id: number): void {
    this.selectedSchoolPeriod = this.schoolPeriods.find(x => x.id === id);
  }

  registerOnChange(fn: Function): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  private GetSchoolPeriods() {
    this.loading = true;
    this.schoolPeriodService.getSchoolPeriods().subscribe({
      next: (data) => {
        this.schoolPeriods = data;
        this.loading = false;
        if (data.length > 0) {
          if (this.stateService.schoolPeriodId != 0) {
            this.selectedSchoolPeriod = data.find(x => x.id === this.stateService.schoolPeriodId);
            if (this.selectedSchoolPeriod === undefined) {
              this.selectedSchoolPeriod = data[0];
            }
            this.stateService.schoolPeriodId = this.selectedSchoolPeriod!.id;
          } else {
            this.selectedSchoolPeriod = data[0];
            this.stateService.schoolPeriodId = this.selectedSchoolPeriod.id;
          }
          this.onSchoolPeriodChange(this.selectedSchoolPeriod!);
        }
      },
      error: (error) => {
        this.loading = false;
      }
    });
  }

}
