<p-dialog
  header="¿Necesitas ayuda?, Agenda una sesión de asesoría con nuestras expertas."
  [modal]="true"
  [(visible)]="visibleModal"
  [style]="{ width: '90vw', maxWidth: '1800px' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  class="modal-Ayuda">

  <div class="modal-body bodyayuda">
    <div class="uprow new-scroll" >
        <div class="row w-full" style="display: flex; flex-wrap: nowrap;">
            <div class="xl:col-3 md:col-4 col-12 justify-content-center flex">
                <div class="card" style="box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);border-radius: 35px;text-align: center; ">
                    <div style="justify-content: center; padding-top: 5px !important;">
                        <img class="imgEquipo" src="https://insightsel.com/wp-content/uploads/2024/02/Adela_Bazbaz.png" alt="DemoImg01" />
                    </div>
                    <span class="span-radial div-title">Adela Bazbaz</span>
                    <div class="div-carrer" ><b    style="height: 72px; display: flex; flex-direction: column;">Licenciada en Pedagogía Organizacional y Educativa<br></b></div>
                    <div class="div-desc" ><span style="height: 135px; display: flex; flex-direction: column;"> Consultora Educativa,<br> especialista en el bienestar<br> emocional a través del <br> mindfulness y el <br> autoconocimiento.</span></div>
                    <div id="botonCalendario" class="col-xl-auto justify-content-center col pr-0" style="padding-left: 0px !important; padding-bottom: 15px !important; padding-top: 5px !important; margin-top: auto;">
                        <button type="button" class="btn btn-medium-new-modal " (click)="openlink('1')" >
                          Agenda una Sesión</button>
                      </div>
                </div>
            </div>
            <div class="xl:col-3 md:col-4 col-12 justify-content-center flex">
                <div class="card" style="box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);border-radius: 35px;text-align: center; ">
                    <div style="justify-content: center; padding-top: 5px !important;">
                        <img class="imgEquipo" src="https://insightsel.com/wp-content/uploads/2024/02/Sofia.png" alt="DemoImg02" />
                    </div>
                    <span class="span-radial div-title">Sofía Salgó</span>
                    <div class="div-carrer"><b    style="height: 72px; display: flex; flex-direction: column;">Psicóloga y Consultora Educativa</b></div>
                    <div class="div-desc" ><span style="height: 135px; display: flex; flex-direction: column;">Especialista en Entorno de <br>Aprendizaje y la construcción de <br>colegios integrales para el pleno <br>desarrollo de cada estudiante.</span></div>
                    <div id="botonCalendario" class="col-xl-auto justify-content-center col pr-0" style="padding-left: 0px !important; padding-bottom: 15px !important; padding-top: 5px !important; margin-top: auto;">
                        <button type="button" class="btn btn-medium-new-modal " (click)="openlink('2')" >
                            Agenda una Sesión</button>
                      </div>
                </div>
            </div>
            <div class="xl:col-3 md:col-4 col-12 justify-content-center flex">
                <div class="card" style="box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);border-radius: 35px;text-align: center; ">
                    <div style="justify-content: center; padding-top: 5px !important;">
                        <img class="imgEquipo" src="https://insightsel.com/wp-content/uploads/2024/02/Miriam.png" alt="DemoImg03" />
                    </div>
                    <span class="span-radial div-title">Miriam Sfadia</span>
                    <div class="div-carrer"><b    style="height: 72px; display: flex; flex-direction: column;">Psicóloga y Maestra en el<br> Desarrollo de la inteligencia <br>emocional</b></div>
                    <div class="div-desc" ><span style="height: 135px; display: flex; flex-direction: column;">Consultora educativa,<br> apasionada por el bienestar<br> social dentro y fuera de los<br> colegios.</span></div>
                    <div id="botonCalendario" class="col-xl-auto justify-content-center col pr-0" style="padding-left: 0px !important; padding-bottom: 15px !important; padding-top: 5px !important; margin-top: auto;">
                        <button type="button" class="btn btn-medium-new-modal " (click)="openlink('3')" >
                            Agenda una Sesión</button>
                      </div>
                </div>
            </div>
            <div class="xl:col-3 md:col-4 col-12 justify-content-center flex">
                <div class="card" style="box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);border-radius: 35px;text-align: center; ">
                    <div style="justify-content: center; padding-top: 5px !important;">
                        <img class="imgEquipo" src="https://insightsel.com/wp-content/uploads/2024/02/Ximena.png" alt="DemoImg04" />
                    </div>
                    <span class="span-radial div-title">Ximena Mendoza</span>
                    <div class="div-carrer"><b    style="height: 72px; display: flex; flex-direction: column;">Psicóloga y Consultora Educativa</b></div>
                    <div class="div-desc"><span  style="height: 135px; display: flex; flex-direction: column;">Especialista en Desarrollo del <br>Bienestar Personal Estudiantil. <br>Guiando escuelas hacia el <br>florecimiento individual de cada <br>estudiante.</span> </div>
                    <div id="botonCalendario" class="col-xl-auto justify-content-center col pr-0" style="padding-left: 0px !important; padding-bottom: 15px !important; padding-top: 5px !important; margin-top: auto;">
                        <button type="button" class="btn btn-medium-new-modal " (click)="openlink('4')" >
                            Agenda una Sesión</button>
                    </div>
                    
                </div>
                
            </div>
           
        </div>
    </div>

    <div class="card-body" style="width: 100%;">
        <div class="row" style="margin-bottom: 20px;">
            <div class="col-12">
                <span
                    style="color:#0A1629;font-weight:900; font-size:26px;line-height:35px;font-family: 'Nunito Sans'">Preguntas Frecuentes</span>
            </div>
        </div>

        <div >
            @for (item of FullHelpFAQ; track $index) {

            <ng-container style="margin-left: 10%; " style="box-shadow: none; border-radius: 35px;">
           
            <div class="card-ayuda-header">
                    <!-- Parrent -->
                <div class="card-ayuda-text">
                    <div class="list-group-item border-modalAyuda cursor-pointer ml-3"
                    style="margin: 0!important;height: fit-content;
                        border-width: 0!important;box-shadow:none!important; 
                        display: grid; grid-template-columns: 1fr;padding: 20px 12px;"
                    (click)="handleCollapse(item)"
                    [ngStyle]="">
                    <div style="padding-top: 6px;display: flex;overflow-x: hidden;"
                    
                    
                   >
                      <div style="align-items: center;display: flex;">
                        <app-collapse-icon #collapseIcon [isCollapse]="item.isCollapse">
                        </app-collapse-icon>
                      </div>
                        <div class="col-md-2 m-t-5 ml-0" style="align-items: center;display: flex;min-width:12800px;">
                            <b class="b-title"> {{item.datosColumna}} </b>
                        </div>
                    </div>
                </div>
                </div>

                <!-- Child -->
        
                <ng-container>
                    <div class="">
                    <div class=" list-group-item border-modalAyuda ml-5 collapse-child"
                        [hidden]="!item.isCollapse">
                        <div style="justify-content: center;display: flex;" class="col-12 py-0">
                            <hr style="margin: 0 0 12px 0;width:100%;" class="py-0">
                        </div>
                        @for (questionFAQ of item.questionFAQ; let index = $index; let last = $last; track questionFAQ; ) {

                        <div style="margin-top: 0;display: flex;overflow-x: hidden; flex-direction: column;"
                         
                         >
                            <!-- <div style="min-width: 52px;"></div> -->
                            <div class="col-md-2 m-t-5 ml-0" style="align-items: center;display: flex;">
                                <b style="margin-left: 20px;color: #67757c!important;font-size: 16px;text-align: left;">  
                                    {{ index + 1 }}.- {{questionFAQ.datosColumna}} 
                                </b>
                                
                            </div>
                            @for (subQuestionFAQ of questionFAQ.subQuestionFAQ; track subQuestionFAQ; let iRom = $index; let last = $last;) {

                            <div style="margin-top: 0;display: flex;overflow-x: hidden;"                                >
                                <!-- <div style="min-width: 52px;"></div> -->
                                <div class="col-md-2 m-t-5 ml-0" style="align-items: center;display: flex;">
                                    <b style="margin-left: 65px;color: #67757c!important;font-size: 16px;text-align: left;"> 
                                        {{ toRomanNumerals(iRom + 1) }}.- {{subQuestionFAQ.datosColumna}} 
                                    </b>
                                </div>
                            </div>
                        }
                        </div>
                    }
                    </div>
                </div>
                </ng-container>
            </div>  
            </ng-container>
        }
        </div>               
        

    </div>
    
</div>


  <ng-template pTemplate="footer"> </ng-template>
</p-dialog>
