import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "@modules/auth/services/auth.service";


export const authenticatedGuard: CanActivateFn = async (route, state) => {
    const authService = inject(AuthService);
    const router = inject(Router);

    try {
        if (!authService.isAuthenticated()) {
            router.navigate(['auth/login']);
            return false;
        } else {
            await authService.CheckIsAdmin();
            return true;
        }
    } catch (error) {
        router.navigate(['auth/login']);
        return false;
    }



}