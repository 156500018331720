import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalAyudaComponent } from '../modal-ayuda/modal-ayuda.component';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
  standalone: true,
  imports: [ModalAyudaComponent, TooltipModule],

})
export class HelpButtonComponent implements OnInit {
  @ViewChild("modalAyuda") ModalAyudaComponent!: ModalAyudaComponent;

  constructor() { }

  ngOnInit(): void {
  }

  originalImageUrl: string = 'assets/new-icons/question1.svg';
  hoverImageUrl: string = 'assets/new-icons/question2.svg';
  imageUrl: string = this.originalImageUrl; // Initial image source

  onMouseOver() {
    this.imageUrl = this.hoverImageUrl; // Change image source on mouse over
  }

  onMouseOut() {
    this.imageUrl = this.originalImageUrl; // Revert image source on mouse out
  }

  openAyuda() {

    this.ModalAyudaComponent.visibleModal = true;
  }
}
