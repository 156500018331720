import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  getLanguage() {
    return localStorage.getItem('language') || 'es-MX';
  }
  setLanguage(code: string) {
    localStorage.setItem('language', code);
  }
  public SchoolSubject$ = new Subject<number>();
  public SchoolPeriodSubject$ = new Subject<number>();
  public selDiagnostics: SelectItem<number>[] = [];
  public SelectedModuleSubject$ = new Subject<number>();
  public selDiagnosticAdded$ = new Subject();
  private _schoolPropertyName = 'schoolId';
  private _schoolPeriodPropertyName = 'schoolPeriodId';
  private _menuPropertyName = 'menuId';

  public deleteSelections() {
    localStorage.removeItem(this._schoolPropertyName);
    localStorage.removeItem(this._schoolPeriodPropertyName);
  }

  private _schoolId: number = 0;
  public get schoolId(): number {
    if (this._schoolId === 0) {
      let schoolId = localStorage.getItem(this._schoolPropertyName);
      if (schoolId) {
        this._schoolId = parseInt(schoolId);
      }
    }
    return this._schoolId;
  }
  public set schoolId(value: number) {
    this._schoolId = value;
    localStorage.setItem(this._schoolPropertyName, value.toString());
    this.SchoolSubject$.next(value);
  }

  private _schoolPeriodId: number = 0;
  public get schoolPeriodId(): number {
    if (this._schoolPeriodId === 0) {
      let schoolPeriodId = localStorage.getItem(this._schoolPeriodPropertyName);
      if (schoolPeriodId) {
        this._schoolPeriodId = parseInt(schoolPeriodId);
      }
    }
    return this._schoolPeriodId;
  }
  public set schoolPeriodId(value: number) {
    this._schoolPeriodId = value;
    this.SchoolPeriodSubject$.next(value);
    localStorage.setItem(this._schoolPeriodPropertyName, value.toString());
  }

  public setSchoolId(schoolId: number) {
    this.schoolId = schoolId;
    this.SchoolSubject$.next(schoolId);
  }

  private _institutionalView: boolean = false;
  public get institutionalView(): boolean {
    const institutionalView = localStorage.getItem('institutionalView');
    if (institutionalView) {
      this._institutionalView = institutionalView === 'true';
    }
    return this._institutionalView;
  }

  public set institutionalView(value: boolean) {
    this._institutionalView = value;
    localStorage.setItem('institutionalView', value.toString());
    setTimeout(() => {
      this.InstitutionViewSubject$.next(value);
    }, 200);
  }

  private _institutionId: number = 0;
  public get institutionId(): number {
    const institutionalString = localStorage.getItem('institutionId');
    if (institutionalString) {
      this._institutionId = parseInt(institutionalString);
    }
    return this._institutionId;
  }
  public set institutionId(value: number) {
    localStorage.setItem('institutionId', value.toString());
    this._institutionId = value;

    setTimeout(() => {
      this.institutionIdSubject$.next(value);
    }, 200);
  }
  public institutionIdSubject$ = new BehaviorSubject<number>(0);
  public InstitutionViewSubject$ = new Subject<boolean>();

  private _menuId = 100;
  public get menuId(): number {
    if (this._menuId === 100) {
      const savedMenuId = localStorage.getItem(this._menuPropertyName);
      if (savedMenuId) {
        this._menuId = parseInt(savedMenuId);
      }
    }
    return this._menuId;
  }

  public set menuId(value: number) {
    this._menuId = value;
    localStorage.setItem(this._menuPropertyName, value.toString());
    this.SelectedModuleSubject$.next(value);
  }

  public setSelectedMenu(menuId: number) {
    this.menuId = menuId;
  }
}
