import { Component, inject, OnInit, importProvidersFrom, OnDestroy } from '@angular/core';
import { LayoutService } from '../service/app.layout.service';
import { BadgeModule } from 'primeng/badge';
import { SidebarModule } from 'primeng/sidebar';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';
import { TranslocoDirective } from '@jsverse/transloco';
import { LanguageComponent } from '@core/components/language/language.component';
import { FormsModule } from '@angular/forms';
import { SchoolService } from '@modules/configuration/pages/schools/school.service';
import { UserService } from '../../modules/configuration/pages/users/user.service';
import { Subscription } from 'rxjs';
import { StateService } from '../../shared/service/state.service';
import { PermissionsUser } from '@modules/configuration/pages/users/adduser/models/permissions-user';

@Component({
    selector: 'app-profilesidebar',
    templateUrl: './app.profilesidebar.component.html',
    styleUrl: './app.profilesidebar.component.scss',
    standalone: true,
    imports: [SidebarModule, BadgeModule, RouterLink, LanguageComponent, TranslocoDirective, FormsModule]
})
export class AppProfileSidebarComponent implements OnInit, OnDestroy {
    public authService = inject(AuthService);
    private schoolService = inject(SchoolService);
    private userService = inject(UserService);
    private _stateService = inject(StateService);
    private router = inject(Router);
    public SchoolSubscription: Subscription = new Subscription();
    isPrivacy = true;
    permissionsUser: PermissionsUser = {
        canActivatePrivacy: false,
        canActivateInstitutionalView: false
    };
    isInstitutionalView: boolean = false;
    constructor(public layoutService: LayoutService) { }
    ngOnDestroy(): void {
        this.SchoolSubscription.unsubscribe();
    }
    ngOnInit(): void {
        this.GetStatusPrivacySchool();
        this.CheckPermissions();
        this.isInstitutionalView = this._stateService.institutionalView;

        this.SchoolSubscription = this._stateService.SchoolSubject$.subscribe({
            next: () => {
                this.GetStatusPrivacySchool();
            }
        });

    }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    GetStatusPrivacySchool() {
        this.schoolService.getStatusPrivacySchool().subscribe({
            next: (data) => {
                this.isPrivacy = data;
            },
        })
    }

    togglePrivacy() {
        setTimeout(() => {
            this.schoolService.changePrivacySchool(this.isPrivacy).subscribe({
                next: (data) => {
                    this.router.navigate(['/']);
                },
                error: (error) => {
                    this.isPrivacy = !this.isPrivacy;
                }
            });
        }, 200);
    }

    toggleInstitutionalView() {
        this._stateService.institutionalView = this.isInstitutionalView;
        this.router.navigate(['/app/insights']);
    }


    CheckPermissions() {
        this.userService.CheckPermissions().subscribe({
            next: (permissionsUser) => {
                this.permissionsUser = permissionsUser;
                if (!permissionsUser.canActivateInstitutionalView) {
                    this._stateService.institutionalView = permissionsUser.canActivateInstitutionalView;
                }
            }, error: (error) => {
                this.permissionsUser = {
                    canActivatePrivacy: false,
                    canActivateInstitutionalView: false
                }
            }
        });
    }


    async singOut() {
        await this.authService.singOut();
    }
}