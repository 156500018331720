import { Injectable } from "@angular/core";

export interface Token {
    jwt: string;
    refreshToken: string;
}

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    public readonly refreshToken = '';

    public getJwt(): string {
        const token = localStorage.getItem('token');
        if (token) {
            return JSON.parse(token).jwt;
        }
        return '';
    }
    public getRefreshToken(): string {
        const token = localStorage.getItem('token');
        if (token) {
            return JSON.parse(token).refreshToken;
        }
        return '';
    }

    public setToken(token: Token): void {
        localStorage.setItem('token', JSON.stringify(token));
    }

    public clearToken(): void {
        localStorage.removeItem('token');
    }

    public getNewToken(): string {
        return localStorage.getItem('refreshToken') || '';
    }






}