<form (ngSubmit)="AnswerSurvey()" [formGroup]="form">
  <ng-container *transloco="let t; prefix: 'sel.maria'">
    <p-dialog
      class="mariaModal"
      header="MarIA Survey"
      [modal]="true"
      [closable]="closable"
      [(visible)]="visibleModal"
      [style]="{ width: '60rem' }"
      [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <div class="text-left text-xl p-0">
        <p>{{ t('we-want-to-hear-you') }}.</p>

        <p-rating formControlName="qualification" [cancel]="false" class="custom-rating">
          <ng-template pTemplate="onicon">
            <span style="font-size: 60px" class="star on-star"> ★ </span>
          </ng-template>
          <ng-template pTemplate="officon">
            <span style="font-size: 60px" class="star off-star">☆</span>
          </ng-template>
        </p-rating>
        <p style="font-weight: 700; font-size: 20px">{{ t('additional-comments') }}:</p>
        <textarea
          formControlName="comment"
          rows="10"
          cols="60"
          pInputTextarea
          style="width: 100%; padding: 20px; border-radius: 30px"
        >
        </textarea>
      </div>
      <ng-template pTemplate="footer">
        <div class="text-center">
          <button
            pButton
            pRipple
            [loading]="loadingSurvey"
            type="submit"
            [label]="t('save')"
            class="p-button-raised"
          ></button>
        </div>
      </ng-template>
    </p-dialog>
  </ng-container>
</form>
