import { HttpBackend, HttpClient, HttpDownloadProgressEvent, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { controllers } from '@shared/utils/controllers';
import { Message } from '../models/message';
import { MessageRequest } from '../models/message-request';
import { MessageParameters } from '../models/message-parameters';
import { environment } from '@environments/environment';
import { StateService } from '@shared/service/state.service';
import { TokenService } from '@shared/service/token.service';
import { MessageMaria } from '@modules/insights/insights.component';

@Injectable({
    providedIn: 'root'
})
export class MariaService {
    public controller: string = controllers.Dashboard.Maria;
    private stateService = inject(StateService);
    private tokenService = inject(TokenService);
    handler: HttpBackend = inject(HttpBackend);
    _httpMaria: HttpClient;

    public openModalSubject = new BehaviorSubject<boolean>(true);
    openModal$ = this.openModalSubject.asObservable();
    messageFromInsight: MessageMaria | undefined;

    constructor(private http: HttpClient) {
        this._httpMaria = new HttpClient(this.handler);
    }

    getParametersMaria(message: string) {
        return firstValueFrom(this.http.get<MessageParameters>(`${this.controller}/GetParametersMaria?message=${message}`));
    }

    getParametersInsight() {
        return firstValueFrom(this.http.get<MessageParameters>(`${this.controller}/GetParametersInsight`));
    }
    getConversationHistory() {
        return this.http.get<Message[]>(`${this.controller}/GetConversationHistory`);
    }

    saveMessageMaria(message: Message) {
        return this.http.post<Message>(`${this.controller}/SaveMessageMaria`, message);
    }

    downloadExcel(): void {
        const schoolId = this.stateService.schoolId;
        this.http.get(`${this.controller}/ResultsStudents?schoolId=${schoolId}`, {
            responseType: 'blob'
        }).subscribe(data => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Students.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        });
    }

    fetchDataObs(data: MessageRequest, token: string): Observable<string> {
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        return new Observable<string>((observer) => {
            fetch(`${environment.urlMaria}/conversation`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data)
            }).then(response => {
                const reader = response.body!.getReader();

                function processText({ done, value }: { done: boolean, value: Uint8Array }): Promise<void> {
                    if (done) {
                        observer.complete();
                        return Promise.resolve();
                    }

                    const chunk = new TextDecoder("utf-8").decode(value);
                    observer.next(chunk); // Emit each chunk
                    return reader.read().then((value) => processText({ done: value.done, value: value.value! }));
                }

                reader.read().then((value) => processText({ done: value.done, value: value.value! }));
            }).catch(error => {
                observer.error(error);
            });
        });
    }

    openModal(closable: boolean = true) {
        this.openModalSubject.next(closable);
    }

    createSurvey(qualification: number, comment: string) {
        const token = this.tokenService.getJwt();
        return this.http.post(`${this.controller}/CreateSurvey`, {
            qualification,
            comment,
            userToken: token

        });
    }


    async getInsights() {
        const parameters = await this.getParametersInsight();
        const token = parameters.token;
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return this._httpMaria.post<any>(`${environment.urlMaria}/insights`, parameters.messageRequest, { headers });
    }

    checkMariaIsActive() {
        return this.http.get<boolean>(`${this.controller}/CheckMariaIsActive`);
    }
}
