import { OnDestroy, OnInit, inject } from '@angular/core';
import { Component } from '@angular/core';
import { AppMenuitemComponent } from '../menuitem/app.menuitem.component';
import { MenuService } from '@layout/service/app.menu.service';
import { TranslocoDirective } from '@jsverse/transloco';
import { Subscription } from 'rxjs';
import { StateService } from '@shared/service/state.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
  standalone: true,
  imports: [AppMenuitemComponent, TranslocoDirective],
})
export class AppMenuComponent implements OnInit, OnDestroy {
  public institutionViewSubscription: Subscription = new Subscription();
  public selectedModuleSubscription: Subscription = new Subscription();
  private _stateService = inject(StateService);
  private menuService = inject(MenuService);
  menuItems = this.menuService.menuItems;
  loading = this.menuService.loading;

  ngOnInit() {
    this.institutionViewSubscription = this._stateService.InstitutionViewSubject$.subscribe({
      next: () => {
        this.menuService.getMenuModule('100');
      },
    });

    this.selectedModuleSubscription = this._stateService.SelectedModuleSubject$.subscribe({
      next: (moduleId) => {
        this.menuService.getMenuModule(moduleId.toString());
      },
    });

    this.menuService.getMenuModule(this._stateService.menuId.toString());
  }
  ngOnDestroy(): void {
    this.institutionViewSubscription.unsubscribe();
  }
}
