import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collapse-icon',
  standalone: true,
  imports: [],
  templateUrl: './collapse-icon.component.html',
  styleUrl: './collapse-icon.component.scss'
})
export class CollapseIconComponent {
  @Input({ required: true }) isCollapse = false;
  constructor() { }

  ngOnInit(): void {
  }
}