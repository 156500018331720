<ul class="layout-menu">
  @if(loading())
  {
  <p>cargando...</p>
  }@else{
  @for (item of menuItems(); track item; let i = $index) {
  @if (!item.separator) {
  <li app-menuitem [item]="item" [index]="i" [root]="true"></li>
  }
  @if (item.separator) {
  <li class="menu-separator"></li>
  }
  }
  }

</ul>