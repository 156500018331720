import { Injectable, inject } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
    providedIn: 'root',
})
export class MessageToastService {
    showWaitFiveMinute() {
        throw new Error('Method not implemented.');
    }


    public readonly key = 'tst';
    private TranslocoService = inject(TranslocoService);
    private confirmationService = inject(ConfirmationService);
    constructor(private messageService: MessageService) { }

    showSuccess(summary: string, detail: string, life: number = 3000) {
        this.messageService.add({ key: this.key, severity: 'success', summary, detail, life: life });
    }



    showError(summary: string, detail: string) {
        this.messageService.add({ key: this.key, severity: 'error', summary, detail });
    }

    showInfo(summary: string, detail: string) {
        this.messageService.add({ key: this.key, severity: 'info', summary, detail });
    }

    showWarning(summary: string, detail: string) {
        this.messageService.add({ key: this.key, severity: 'warn', summary, detail });
    }

    showDeletedSuccessfully() {
        const success = this.TranslocoService.translate('general.success')
        const deletedSuccess = this.TranslocoService.translate('general.deletedsuccess')
        this.showSuccess(success, deletedSuccess);
    }

    showSavedSuccessfully() {
        const success = this.TranslocoService.translate('general.success')
        const savedSuccess = this.TranslocoService.translate('general.savedsuccess')
        this.showSuccess(success, savedSuccess);
    }

    confirmation(): Promise<boolean> {
        const sure = this.TranslocoService.translate('general.sure');
        const yes = this.TranslocoService.translate('general.yes');
        const no = this.TranslocoService.translate('general.no');
        const mouseEvent = event as MouseEvent;
        return new Promise<boolean>((resolve) => {
            this.confirmationService.confirm({
                target: mouseEvent.target as EventTarget,
                message: sure,
                acceptLabel: yes,
                rejectLabel: no,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }

    confirmationProgress(): Promise<boolean> {
        const sure = this.TranslocoService.translate('general.sureProgress');
        const yes = this.TranslocoService.translate('general.yes');
        const no = this.TranslocoService.translate('general.no');
        const mouseEvent = event as MouseEvent;
        return new Promise<boolean>((resolve) => {
            this.confirmationService.confirm({
                target: mouseEvent.target as EventTarget,
                message: sure,
                acceptLabel: yes,
                rejectLabel: no,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }


    selectAtLeastOneRecord() {
        const info = this.TranslocoService.translate('general.info')
        const atLeastOne = this.TranslocoService.translate('general.atLeastOne')
        this.showInfo(info, atLeastOne);
    }

    showEmailSentSuccessfully() {
        const success = this.TranslocoService.translate('general.success')
        const emailSentSuccessfully = this.TranslocoService.translate('general.emailsSentSuccessfully')
        this.showSuccess(success, emailSentSuccessfully);
    }
}
