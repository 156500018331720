<p-dropdown
  [options]="schools"
  [(ngModel)]="selectedSchool"
  optionLabel="name"
  [filter]="true"
  [disabled]="disabled"
  styleClass="{{ styleClass }}"
  [loading]="loading"
  (onChange)="onSchoolChange($event.value)"
  placeholder="Select a School"
  [ngClass]="{ borderless: !modal }"
/>
