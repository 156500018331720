import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SchoolRequest } from "./add-school/school-request-form";
import { SchoolsResponse } from "./models/Response/schools-response";
import { SchoolResponse } from "./models/Response/school-response";
import { SchoolsForSearch } from "@modules/sel/pages-institution/schools/model/response/schools-for-search";


@Injectable({
    providedIn: "root"
})
export class SchoolService {

    private controller = "School";
    private _httpClient = inject(HttpClient);

    saveSchool(school: SchoolRequest) {
        if (school.id) {
            return this._httpClient.put<number>(`${this.controller}/UpdateSchool`, school)
        } else {
            return this._httpClient.post<number>(`${this.controller}/CreateSchool`, school)
        }
    }

    getStatusPrivacySchool() {
        return this._httpClient.get<boolean>(`${this.controller}/GetStatusPrivacy`)
    }

    changePrivacySchool(status: boolean) {
        return this._httpClient.put<boolean>(`${this.controller}/ChangePrivacy?privacy=${status}`, null)
    }

    getSchools() {
        return this._httpClient.get<SchoolsResponse[]>(`${this.controller}/GetSchools`)
    }

    getSchoolById(id: number) {
        return this._httpClient.get<SchoolResponse>(`${this.controller}/GetSchoolById/${id}`)
    }

    deleteSchool(id: number) {
        return this._httpClient.delete<boolean>(`${this.controller}/DeleteSchool/${id}`)
    }

    changeStatus(idSchool: number, status: boolean) {
        return this._httpClient.put<boolean>(`${this.controller}/ChangeStatus/${idSchool}?Status=${status}`, null)
    }

    getSchoolsByIdSchoolPeriod(idSchoolPeriod: number) {
        return this._httpClient.get<SchoolsResponse[]>(`${this.controller}/GetSchoolsByIdSchoolPeriod/${idSchoolPeriod}`)
    }

    DownloadExcelInterventionGroups() {
        return this._httpClient.get(`${this.controller}/GetGroupsGradeIntervention`, { responseType: 'blob' });
    }

    DownloadExcelInterventionGroupsStudents() {
        return this._httpClient.get(`${this.controller}/GetGroupsGradeStudentIntervention`, { responseType: 'blob' });
    }

    DownloadExcelResultsStudents() {
        return this._httpClient.get(`${this.controller}/GetExcelResultsStudents`, { responseType: 'blob' });
    }
    GetSchoolsForSearch() {
        return this._httpClient.get<SchoolsForSearch[]>(`${this.controller}/GetSchoolsForSearch`);
    }

}