import { DeleteUserComponent } from '../../modules/configuration/pages/users/delete-user/delete-user.component';
import { EMPTY } from 'rxjs';
export enum NameData {
    NAME = "name",
    PROGRESSBAR = "Score",
    TOPIC = "topic",
    SCHOOL = "school",
    STUDENTNAME = "studentName",
    SCHOOL_NAME = "schoolName",
    GROUPNAME = "groupName",
    DESCRIPTION = "description",
    STARTDDATE = "startDate",
    GRADE = "gradeName",
    ENDDATE = "endDate",
    LASTLOGIN = "lastLogin",
    ROLE = "role",
    ISACTIVE = "isActive",
    EMAIL = "email",
    GROUPS = "groups",
    SCORE = "score",
    RADIO = "radio",
    CHECK = "check",
    CHECK2 = "check2",
    ID = "id",
    STUDENTID = "studentId",
    SCHOOL_ID = "schoolId",
    NUM_STUDENTS = "numStudents",
    NUM_SCHOOLS = "numSchools",
    NUM_GROUPS = "numGroups",
    NUM_TEACHERS = "numTeachers",
    NUM_ADMINS = "numAdmins",
    REPORT = "report",
    PLAN_SEL = "planSel",
    PLAN_EDIFY = "planEdify",
    MARIA = "maria",
    ISVALIDATED = "isValidated",
    SELDIMENSIONSSCORE = "selDimensionsScore",
    SELDIMENSIONSCORE = "selDimensionScore",
    SKILLDIMENSIONSSCORE = "selDimensionsScore",
    STYLEARNDIMENSIONSSCORE = "skillDimensionsScore",
    SKILLDIMENSIONSCORE = "skillDimensionScore",
    STYLEARNDIMENSIONSCORE = "stylearnDimensionScore",
    SELTOPICSCORE = "selTopicScore",
    SKILLTOPICSCORE = "skillTopicScore",
    STYLEARNTOPICSCORE = "stylearnTopicScore",
    STUDENTSCOUNT = "studentsCount",
    USERS = "users",
    REPORTS = "reports",
    INTERVENTIONS = "interventions",
    LOGIN = "login",
    INSTITUTION_NAME = "institutionName",
    VALIDATION_PERCENTAGE = "validationPercentage",
    SOCIALINKDIMENSIONSSCORE = "socialinkDimensionsScore",
    SOCIALINKDIMENSIONSCORE = "socialinkDimensionScore",
}

export enum TypeData {
    NAME = "name",
    STUDENTNAME = "studentName",
    SCHOOL_NAME = "schoolName",
    LASTLOGIN = "lastLogin",
    ISACTIVE = "isActive",
    SCORE = "score",
    CHECK = "check",
    CHECK2 = "check2",
    EMAIL = "email",
    ID = "id",
    STUDENTID = "studentId",
    NUMBER = "NUMBER",
    ROLE = "role",
    GROUPS = "groups",
    YESNO = "YESNO",
    REPORT = "report",
    REPORT_GENERAL = "reportGeneral",
    DEFAULT = "default",
    ISVALIDATED = "isValidated",
    SELDIMENSIONSCORE = "selDimensionScore",
    SELDIMENSIONSSCORE = "selDimensionsScore",
    SKILLDIMENSIONSCORE = "skillDimensionScore",
    SKILLDIMENSIONSSCORE = "skillDimensionsScore",
    STYLEARNDIMENSIONSCORE = "stylearnDimensionScore",
    STYLEARNDIMENSIONSSCORE = "stylearnDimensionsScore",
    SOCIALINKDIMENSIONSCORE = "socialinkDimensionScore",
    SOCIALINKDIMENSIONSSCORE = "socialinkDimensionsScore",
    SELTOPICSCORE = "selTopicScore",
    SKILLTOPICSCORE = "skillTopicScore",
    STYLEARNTOPICSCORE = "stylearnTopicScore",
    STUDENTSCOUNT = "studentsCount",
    VALIDATION_PERCENTAGE = "validationPercentage",
}

export enum TableTitle {
    USERS = "users",
    SCHOOLS = "schools",
    SCHOOLPERIODS = "schoolPeriods",
    GRADES = "grades",
    STUDENTSNOTCOMPLETED = "studentsNotCompleted",
    STUDENTSREQUIREATTENTION = "studentsRequireAttention",
    STUDENTSSKILLSREQUIREATTENTION = "studentsSkillsRequireAttention",
    STUDENTSVALIDATION = "studentsValidation",
    RESULTSSTUDENT = "resultsStudent",
    STUDENTS = "students",
    EMPTY = "empty",
    SELINTERESTGROUP = "selInterestGroup",
    TEACHERS = "teachers",
    INSTITUTIONS = "institutions",
    GROUPS = "groups",
    RESULTSSCHOOL = "resultsSchool",
    RELATIONSHIPSMATRIX = "relationshipsMatrix"

}

export enum ModalTitle {
    ADD = "addTitle",
    EDIT = "editTitle",
}

export enum RoutesStudent {
    LOGIN = "/student/auth/login",
    REGISTER = "/student/auth/register",
    DASHBOARD = "/student/dashboard",
    RECOVER_PASSWORD = "/student/auth/recover-password",
    JOIN_GROUP = "/student/dashboard/join-group",
    ANSWER_SEL_SURVEY = "/student/dashboard/SEL/answer-sel-survey",
    ANSWER_SKILLS_SURVEY = "/student/dashboard/Skills/answer-skills-survey",
    ANSWER_STYLES_SURVEY = "/student/dashboard/Styles/answer-styles-survey",
    ANSWER_SOCIALINK_SURVEY = "/student/dashboard/Socialink/answer-socialink-survey",
}

export enum ModuleEnum {
    SEL = 100,
    EDIFY = 200,
    SKILLS = 300,
    STYLE = 400,
    SOCIALINK = 500,
}

export enum ColorCodes {
    RED = 'rgb(255, 87, 87)',
    YELLOW = '#ffde59',
    GREEN = '#7ed957',
    DARK_GREEN = '#008037',
}