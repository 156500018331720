import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { inject } from "@angular/core";
import { TokenService } from "@shared/service/token.service";
import { controllers } from "@shared/utils/controllers";
import { AuthStudentService } from "@modules-student/auth-student/auth-student.service";

export const tokenUserInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const tokenService = inject(TokenService);
    const authStudentService = inject(AuthStudentService);

    const urlsNext = [controllers.Auth, controllers.AuthStudent, 'i18n']

    if (urlsNext.some(url => req.url.includes(url))) {
        return next(req);
    }


    if (req.url.includes('StudentApi')) {
        req = req.clone({
            setHeaders: {
                Authorization: `Bearer ${authStudentService.getJwt()}`
            }
        });
        return next(req);
    }

    req = req.clone({
        setHeaders: {
            Authorization: `Bearer ${tokenService.getJwt()}`
        }
    });
    return next(req);
}