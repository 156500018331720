<ng-container *transloco="let t; prefix: 'menu'">
  <div class="layout-topbar w-full mb-3">
    <div class="topbar-content">
      <div class="topbar-start mb-3">
        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
          <i class="pi pi-bars"></i>
        </button>
      </div>

      <div class="topbar-end mb-3">
        <ul class="topbar-menu justify-content-end">
          <li class="md:ml-3 w-3 md:w-6" style="min-width: 230px">
            <app-school-period-select (OnChanges)="getSchools($event)"></app-school-period-select>
          </li>
          <li class="md:ml-3 w-3 md:w-6" [hidden]="institutionalView" style="min-width: 230px">
            <app-school-select (OnChanges)="schoolChange($event)" #schoolSelect></app-school-select>
          </li>

          <li class="md:ml-3 w-3 md:w-6" [hidden]="!institutionalView" style="min-width: 230px">
            <p-dropdown
              [filter]="true"
              [(ngModel)]="institutionSelected"
              [options]="institutions"
              (onChange)="institutionChange($event)"
              #institutionSelect
            ></p-dropdown>
            <!-- <app-school-select (OnChanges)="schoolChange($event)" #schoolSelect></app-school-select> -->
          </li>
          @if (_authService.isAdmin) {
            <li class="md:ml-3">
              <button
                pButton
                type="button"
                icon="pi pi-cog"
                class="p-button-text p-button-secondary p-button-rounded flex-shrink-0 btn-topbar"
                (click)="menu.toggle($event)"
              ></button>
              <!-- <p-menu #menu [model]="items" [popup]="true" /> -->
              <p-menu #menu [model]="items" [popup]="true">
                <ng-template pTemplate="submenuheader" let-item>
                  <span class="text-primary font-bold">{{ t(item.label) }}</span>
                </ng-template>
                <ng-template pTemplate="item" let-item>
                  <ng-container>
                    <a [routerLink]="item.routerLink" class="p-menuitem-link">
                      <span [class]="item.icon"></span>
                      <span class="ml-2 w-6">{{ t(item.label) }}</span>
                    </a>
                  </ng-container>
                </ng-template>
              </p-menu>
            </li>
          }

          <li>
            <!-- Espacio vacío -->
          </li>

          <li>
            <div
              [hidden]="institutionalView"
              [ngClass]="{ 'show ml-3': showAutoComplete, hide: !showAutoComplete }"
              class="autocomplete-container"
            >
              <p-autoComplete
                [suggestions]="filteredStudents"
                field="label"
                [multiple]="false"
                [(ngModel)]="selectedStudent"
                (completeMethod)="filterStudent($event)"
                [virtualScroll]="true"
                (onSelect)="onStudentSelect($event)"
                [virtualScrollItemSize]="28"
                [dropdown]="true"
                class="searchInput"
              />
            </div>
            <div
              [hidden]="!institutionalView"
              [ngClass]="{ 'show ml-3': showAutoComplete, hide: !showAutoComplete }"
              class="autocomplete-container"
            >
              <p-autoComplete
                [suggestions]="filteredSchools"
                field="label"
                [multiple]="false"
                [(ngModel)]="selectedSchoolForSearch"
                (completeMethod)="filterSchool($event)"
                [virtualScroll]="true"
                (onSelect)="onSchoolSelect($event)"
                [virtualScrollItemSize]="28"
                [dropdown]="true"
                class="searchInput"
              />
            </div>
          </li>

          <li class="md:ml-3" style="position: relative">
            <button
              pButton
              type="button"
              class="p-button-text p-button-secondary p-button-rounded flex-shrink-0 btn-topbar"
              style="z-index: 2"
              (click)="onSearch()"
            >
              <i class="fas fa-search"></i>
            </button>
            <div class="fondoBtn"></div>
          </li>

          <li class="topbar-profile md:ml-3">
            <button
              type="button"
              class="p-link flex justify-content-center align-items-center"
              (click)="onProfileButtonClick()"
              style="width: 42px; height: 42px; background-color: white"
            >
              <img src="assets/new-icons/userIcon.svg" alt="Profile" style="width: 22px; height: 22px" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
