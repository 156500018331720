import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { StateService } from "@shared/service/state.service";
import { Observable } from "rxjs";

export const contentLenguageInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    if (req.url.includes('i18n')) {
        return next(req);
    }
    const translocoService = inject(TranslocoService);
    const stateService = inject(StateService);
    const language = translocoService.getActiveLang();
    req = req.clone({
        setHeaders: {
            'Accept-Language': language,
            'SchoolId': stateService.schoolId.toString(),
            'SchoolPeriodId': stateService.schoolPeriodId.toString(),
            'InstitutionId': stateService.institutionId.toString()
        }
    });
    return next(req);
}