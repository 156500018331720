import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations'
import { routes } from './app.routes';
import { HttpClient, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { MessageToastService } from './shared/service/message-toast.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { baseInterceptor } from '@core/interceptors/base.interceptor';
import { errorInterceptor } from '@core/interceptors/error.interceptor';
import { contentLenguageInterceptor } from '@core/interceptors/conten-lenguage.interceptor';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco } from '@jsverse/transloco';
import { tokenUserInterceptor } from '@core/interceptors/token.interceptor';
import localeEsMX from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeEsMX);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withFetch(), withInterceptors([errorInterceptor, baseInterceptor, tokenUserInterceptor, contentLenguageInterceptor])),
    provideAnimations(),
    MessageToastService,
    MessageService,
    ConfirmationService,
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: ['en-US', 'es-MX'],
        defaultLang: localStorage.getItem('language') ?? 'es-MX',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
        failedRetries: 2,
        missingHandler: {
          logMissingKey: true
        },
      },
      loader: TranslocoHttpLoader
    })
  ]
};

