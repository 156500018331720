import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { SchoolsResponse } from '@modules/configuration/pages/schools/models/Response/schools-response';
import { SchoolService } from '@modules/configuration/pages/schools/school.service';
import { FormImports } from '@shared/shared.import';
import { DropdownModule } from 'primeng/dropdown';
import { StateService } from '../../../service/state.service';
import { NgClass } from '@angular/common';
import { AuthService } from '@modules/auth/services/auth.service';
import { UserLoginResponse } from '@modules/auth/pages/login/login.response';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-school-select',
  standalone: true,
  imports: [DropdownModule, ...FormImports, TranslocoDirective, NgClass],
  templateUrl: './school-select.component.html',
  styleUrl: './school-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SchoolSelectComponent,
      multi: true
    }
  ]
})
export class SchoolSelectComponent implements ControlValueAccessor {
  @Output() OnChanges = new EventEmitter<number>();
  @Input() styleClass: string = '';
  public schools: SchoolsResponse[] = [];
  selectedSchool: SchoolsResponse | undefined;
  private stateService = inject(StateService);
  private schoolSubscription: Subscription = new Subscription();
  @Input() modal: boolean = false;
  public _authService = inject(AuthService);
  user: UserLoginResponse = {
    email: '',
    id: 0,
    name: '',
    schoolId: undefined
  };
  loading: boolean = false;
  disabled: boolean = false;
  private schoolService = inject(SchoolService);


  constructor() {
    this.user = this._authService.getSessionUser();
    this.schoolSubscription = this.stateService.SchoolSubject$.subscribe({
      next: (schoolId) => {
        this.selectedSchool = this.schools.find(x => x.id === schoolId);
      }, error: (error) => {

      }
    })
  }


  public onSchoolChange(school: SchoolsResponse) {
    this.selectedSchool = school;
    this._onChange(school.id);
    this._onTouched();
    this.OnChanges.emit(school.id);
  }



  public GetSchools(idSchoolPeriod: number) {
    this.loading = true;
    this.selectedSchool = undefined;
    this.schoolService.getSchoolsByIdSchoolPeriod(idSchoolPeriod).subscribe({
      next: (data) => {
        this.schools = data;
        this.loading = false;
        if (data.length > 0) {
          if (this.stateService.schoolId && data.find(x => x.id === this.stateService.schoolId)) {
            if (this._authService.isAdmin) {
              this.selectedSchool = data.find(x => x.id === this.stateService.schoolId);
            } else {
              this.selectedSchool = this.user.schoolId ? data.find(x => x.id === this.user.schoolId) : data[0];
            }
          } else {
            if (this._authService.isAdmin) {
              this.selectedSchool = data[0];
            } else {
              this.selectedSchool = this.user.schoolId ? data.find(x => x.id === this.user.schoolId) : data[0];
            }
          }
          this.onSchoolChange(this.selectedSchool!);
        }
      },
      error: (error) => {
        this.loading = false;
      }
    })
  }


  //#region ControlValueAccessor
  private _onChange: Function = (_value: number) => { };
  private _onTouched: Function = (_value: number) => { };


  writeValue(id: number): void {
    this.selectedSchool = this.schools.find(x => x.id === id);
  }

  registerOnChange(fn: Function): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


}
