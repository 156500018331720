import { Injectable, inject } from "@angular/core";
import { UserResponse } from "./adduser/models/userResponse";
import { HttpClient } from "@angular/common/http";
import { SelectItemGroup } from "primeng/api";
import { SaveUserRequest } from "./adduser/models/saveUserRequest";
import { BulkUploadUsersResponse } from './adduser/models/bulk-upload-users-response';
import { PermissionsUser } from "./adduser/models/permissions-user";


@Injectable({
    providedIn: "root"
})
export class UserService {


    private controller = "User";
    private _httpClient = inject(HttpClient);

    getUsersByModuleAndRole(moduleId: number, roleId: number) {
        return this._httpClient.get<UserResponse[]>(`${this.controller}/GetUsers?ModuleId=${moduleId}&roleId=${roleId}`)
    }


    saveUser(user: SaveUserRequest) {
        if (user.id == 0) {
            return this._httpClient.post<number>(`${this.controller}/SaveUser`, user)

        } else {
            return this._httpClient.put<number>(`${this.controller}/EditUser`, user)
        }
    }

    getUserbyEmail(email: string) {
        return this._httpClient.get<UserResponse>(`${this.controller}/GetUserByEmail?email=${email}`)
    }

    sendPasswordToUsers(emails: string[]) {
        return this._httpClient.post<boolean>(`${this.controller}/SendPasswordToUsers`, emails)
    }

    deleteUser(idUser: number) {
        return this._httpClient.delete<boolean>(`${this.controller}/DeleteUser/${idUser}`)
    }

    deleteUserRole(idUser: number, roleModuleId: string) {
        return this._httpClient.delete<boolean>(`${this.controller}/DeleteUserRole?IdUser=${idUser}&ModuleRoleId=${roleModuleId}`)
    }

    changeStatus(idUser: number, status: boolean) {
        return this._httpClient.put<boolean>(`${this.controller}/ChangeStatus/${idUser}?Status=${status}`, null)
    }

    CheckPermissions() {
        return this._httpClient.get<PermissionsUser>(`${this.controller}/CheckPermissions`)
    }

    DownloadExcelUsers() {
        return this._httpClient.get(`${this.controller}/GetUsersExcel`, { responseType: 'blob' });
    }

    downloadFormatExcel(isEdition: boolean) {
        return this._httpClient.get(`${this.controller}/DownloadFormatExcel?isEdition=${isEdition}`, { responseType: 'blob' });
    }

    uploadBulkCreateUsers(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        return this._httpClient.post<BulkUploadUsersResponse>(`${this.controller}/BulkCreateUsers`, formData);
    }

    uploadBulkUpdateUsers(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        return this._httpClient.post<BulkUploadUsersResponse>(`${this.controller}/BulkUpdateUsers`, formData);
    }

}