import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { MessageToastService } from './shared/service/message-toast.service';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MarIASurveyComponent } from "./shared/components/sel/mar-iasurvey/mar-iasurvey.component";



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, FormsModule, ConfirmPopupModule, MarIASurveyComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  private service = inject(MessageToastService);

  title = 'Insight For Education';
  key: string = '';



  constructor(private primengConfig: PrimeNGConfig) {
    this.key = this.service.key;
    primengConfig.ripple = true;




  }

}
