<div class="floating-button">
  <button type="button" class="btn btn-medium-new-btnhelp" (click)="openAyuda()">
    <img
      class="imgEquipo cursor-pointer hover-img"
      id="helpbtnmodal"
      (mouseover)="onMouseOver()"
      (mouseout)="onMouseOut()"
      alt="Download"
      [src]="imageUrl"
      alt="helpbtn1"
      pInputText
      pTooltip="¿Necesitas Ayuda?"
      tooltipPosition="top"
    />
  </button>
</div>

<app-modal-ayuda #modalAyuda></app-modal-ayuda>
