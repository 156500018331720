import { HttpClient } from '@angular/common/http';
import { Injectable, inject, effect } from '@angular/core';
import { Router } from '@angular/router';
import { LoginResponse, UserLoginResponse } from '@auth/pages/login/login.response';
import { StateService } from '@shared/service/state.service';
import { TokenService } from '@shared/service/token.service';
import { controllers } from '@shared/utils/controllers';
import { firstValueFrom, Observable } from 'rxjs';
import { schoolPeriodTable } from '../../configuration/pages/school-periods/school-period.table';

const authController = controllers.Auth;
const userController = controllers.Configuration.User;
@Injectable({
  providedIn: 'root',

})
export class AuthService {
  private _httpClient = inject(HttpClient);
  private _tokenService = inject(TokenService);
  private _stateService = inject(StateService);
  private _router = inject(Router);
  public isAdmin: boolean | undefined;

  constructor() {
  }

  public code: string = '';

  public async CheckIsAdmin() {
    if (this.isAdmin == undefined) {
      this.isAdmin = await firstValueFrom(this.isAdminPlatform());
    }
  }

  login(email: string, password: string): Observable<LoginResponse> {
    const params = { email, password };
    return this._httpClient.get<LoginResponse>(`${authController}/login`, { params });
  }

  loginEncryptPassword(email: string, password: string): Observable<LoginResponse> {
    const params = { email, password };
    return this._httpClient.get<LoginResponse>(`${authController}/loginEncryptedPassword`, { params });
  }

  saveSessionUser(loginResponse: LoginResponse) {
    this._tokenService.setToken({ jwt: loginResponse.token, refreshToken: loginResponse.refreshToken });

    localStorage.setItem('user', JSON.stringify(loginResponse.user));
  }
  private isAdminPlatform() {
    return this._httpClient.get<boolean>(`${userController}/IsAdminPlatform`);
  }

  singOut() {
    this.isAdmin = undefined;
    return new Promise<void>((resolve, reject) => {
      this._tokenService.clearToken();
      localStorage.removeItem('user');
      setTimeout(() => {
        this._router.navigate(['/auth/login']);
        this._stateService.deleteSelections();
        resolve();
      }, 300);
    });
  }

  getSessionUser(): UserLoginResponse {
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
    return { id: 0, email: '', name: '', schoolName: '' };
  }

  isAuthenticated(): boolean {
    return this._tokenService.getJwt() !== '';
  }


  requestCodeForNewPassword(email: string): Observable<boolean> {
    return this._httpClient.post<boolean>(`${authController}/CreateCodeForNewPassword?email=${email}`, {});
  }

  verifyCodeForNewPassword(email: string, code: string): Observable<boolean> {
    return this._httpClient.get<boolean>(`${authController}/VerifyCodeForNewPassword?email=${email}&code=${code}`);
  }

  changePassword(code: string, newPassword: string): Observable<boolean> {
    return this._httpClient.post<boolean>(`${authController}/ChangePassword`, { code, newPassword });
  }

  canActivateMaria() {
    const user = this.getSessionUser();
    return user.email === 'daniel@grupoinsight.mx';
  }


}
