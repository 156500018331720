import { Component, inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormImports, PrimeNgImports } from '@shared/shared.import';
import { Subscription } from 'rxjs';
import { MariaService } from '../../../../modules/sel/pages/maria/service/maria.service';
import { MessageToastService } from '../../../service/message-toast.service';
import { TranslocoDirective } from '@jsverse/transloco';
interface SurveyForm {
  qualification: FormControl<number>;
  comment: FormControl<string | null>;
}

@Component({
  selector: 'app-mar-iasurvey',
  standalone: true,
  imports: [...PrimeNgImports, ...FormImports, TranslocoDirective],
  templateUrl: './mar-iasurvey.component.html',
  styleUrl: './mar-iasurvey.component.scss'
})
export class MarIASurveyComponent implements OnDestroy {
  form: FormGroup<SurveyForm>;
  closable: boolean = true;
  visibleModal: boolean = false;
  loadingSurvey = false;
  openModalSubscription: Subscription = new Subscription();
  _mariaService = inject(MariaService);
  private messageToastService = inject(MessageToastService);
  private first = true;
  constructor() {
    this.form = new FormGroup<SurveyForm>({
      qualification: new FormControl<number>({ value: 0, disabled: false }, { nonNullable: true, validators: [Validators.required] }),
      comment: new FormControl<string | null>({ value: '', disabled: false }, { nonNullable: false }),
    });

    this.openModalSubscription = this._mariaService.openModalSubject.subscribe(({
      next: (value) => {
        if (this.first) {
          this.first = false;
          return;
        }
        this.visibleModal = true;
        this.closable = value;
      }
    }))
  }
  ngOnDestroy(): void {
    this.openModalSubscription.unsubscribe();
  }

  AnswerSurvey() {
    this.loadingSurvey = true;
    this._mariaService.createSurvey(this.form.value.qualification!, this.form.value.comment!).subscribe({
      next: (value) => {
        this.loadingSurvey = false;
        this.messageToastService.showSavedSuccessfully();
        this.visibleModal = false;
      },
      error: (error) => {
        this.loadingSurvey = false;
      }

    });
  }
}
