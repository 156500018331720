import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AddSchoolPeriodRequest } from './add-school-period/models/add-school-period-request';
import { SchoolPeriodTableResponse } from "./models/schoolPeriod-table-response";
import { SchoolPeriodResponse } from "./models/school-period-response";


@Injectable({
    providedIn: "root"
})
export class SchoolPeriodService {
    private controller = "SchoolPeriod";
    private _httpClient = inject(HttpClient);

    saveSchoolPeriod(schoolPeriod: AddSchoolPeriodRequest) {
        if (schoolPeriod.id) {
            return this._httpClient.put<number>(`${this.controller}/UpdateSchoolPeriod/${schoolPeriod.id}`, schoolPeriod)
        } else {
            return this._httpClient.post<number>(`${this.controller}/CreateSchoolPeriod`, schoolPeriod)
        }
    }

    getSchoolPeriods() {
        return this._httpClient.get<SchoolPeriodTableResponse[]>(`${this.controller}/GetSchoolPeriods`)
    }
    getSchoolPeriodById(id: number) {
        return this._httpClient.get<SchoolPeriodResponse>(`${this.controller}/GetSchoolPeriodById/${id}`)
    }

    deleteSchoolPeriod(id: number) {
        return this._httpClient.delete(`${this.controller}/DeleteSchoolPeriod/${id}`)
    }
    // deleteSchool(id: number) {
    //     return this._httpClient.delete<boolean>(`${this.controller}/DeleteSchool/${id}`)
    // }

    // changeStatus(idSchool: number, status: boolean) {
    //     return this._httpClient.put<boolean>(`${this.controller}/ChangeStatus/${idSchool}?Status=${status}`, null)
    // }
}