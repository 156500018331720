import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { PrimeNgImports } from '@shared/shared.import';
import { NgStyle, NgClass } from '@angular/common';
import { CollapseIconComponent } from '@shared/components/collapse-icon/collapse-icon.component';


export interface helpFAQ {

  idFAQ: number;
  idPregunta: number;
  cabecera: boolean;
  subCabecera: boolean;
  datosColumna: string;
  fechaCreo: Date;
  activo: boolean;
  isCollapse: false;
}



export interface FullhelpFAQ {

  idFAQ: number;
  idPregunta: number;
  cabecera: boolean;
  subCabecera: boolean;
  datosColumna: string;
  fechaCreo: Date;
  activo: boolean;
  isCollapse: false;
  questionFAQ: questionHelpFAQ[];



}

export interface questionHelpFAQ {

  idFAQ: number;
  idPregunta: number;
  cabecera: boolean;
  subCabecera: boolean;
  datosColumna: string;
  fechaCreo: Date;
  activo: boolean;
  isCollapse: false;
  subQuestionFAQ: subbHelpFAQ[];



}

export interface subbHelpFAQ {

  idFAQ: number;
  idPregunta: number;
  cabecera: boolean;
  subCabecera: boolean;
  datosColumna: string;
  fechaCreo: Date;
  activo: boolean;
  isCollapse: false;
}

@Component({
  selector: 'app-modal-ayuda',
  templateUrl: './modal-ayuda.component.html',
  styleUrls: ['./modal-ayuda.component.scss'],
  standalone: true,
  imports: [...PrimeNgImports, NgStyle, CollapseIconComponent, NgClass],
})
export class ModalAyudaComponent implements OnInit {
  visibleModal: boolean = false;


  helpFAQ: helpFAQ[] = [];
  FullHelpFAQ: FullhelpFAQ[] = [
    {
      "idFAQ": 1,
      "idPregunta": 1,
      "cabecera": true,
      "subCabecera": false,
      "datosColumna": "¿Cómo se descargan los reportes generales?",
      "fechaCreo": new Date("2024-07-19T08:55:11.757"),
      "activo": true,
      "isCollapse": false,
      "questionFAQ": [
          {
              "idFAQ": 2,
              "idPregunta": 1,
              "cabecera": false,
              "subCabecera": false,
              "datosColumna": "Dirígete a la barra de menú, a la izquierda de tu pantalla.",
              "fechaCreo":new Date( "2024-07-19T08:55:11.76"),
              "activo": true,
              "isCollapse": false,
              "subQuestionFAQ": []
          },
          {
              "idFAQ": 3,
              "idPregunta": 1,
              "cabecera": false,
              "subCabecera": false,
              "datosColumna": "Selecciona la opción “Resúmen”",
              "fechaCreo": new Date("2024-07-19T08:55:11.767"),
              "activo": true,
              "isCollapse": false,
              "subQuestionFAQ": []
          },
          {
              "idFAQ": 4,
              "idPregunta": 1,
              "cabecera": false,
              "subCabecera": false,
              "datosColumna": "Da click en el botón “Buscar”",
              "fechaCreo": new Date("2024-07-19T08:55:11.773"),
              "activo": true,
              "isCollapse": false,
              "subQuestionFAQ": []
          },
          {
              "idFAQ": 5,
              "idPregunta": 1,
              "cabecera": false,
              "subCabecera": false,
              "datosColumna": "Haz click en el botón azul “Descargar reporte”",
              "fechaCreo": new Date("2024-07-19T08:55:11.777"),
              "activo": true,
              "isCollapse": false,
              "subQuestionFAQ": []
          },
          {
              "idFAQ": 6,
              "idPregunta": 1,
              "cabecera": false,
              "subCabecera": false,
              "datosColumna": "Selecciona la opción “Reporte Pro”",
              "fechaCreo": new Date("2024-07-19T08:55:11.78"),
              "activo": true,
              "isCollapse": false,
              "subQuestionFAQ": []
          },
          {
              "idFAQ": 7,
              "idPregunta": 1,
              "cabecera": false,
              "subCabecera": false,
              "datosColumna": "Verás en tus archivos descargados el reporte general en formato pdf.",
              "fechaCreo": new Date("2024-07-19T08:55:11.783"),
              "activo": true,
              "isCollapse": false,
              "subQuestionFAQ": []
          }
      ]
  },
  {
    "idFAQ": 8,
    "idPregunta": 8,
    "cabecera": true,
    "subCabecera": false,
    "datosColumna": "¿Cómo puedo comparar los resultados de diferentes grupos?",
    "fechaCreo": new Date("2024-07-19T15:20:48.707"),
    "activo": true,
    "isCollapse": false,
    "questionFAQ": [
        {
            "idFAQ": 9,
            "idPregunta": 8,
            "cabecera": false,
            "subCabecera": false,
            "datosColumna": "Dirígete a la barra de menú, a la izquierda de tu pantalla",
            "fechaCreo": new Date ("2024-07-19T15:20:48.71"),
            "activo": true,
            "isCollapse": false,
            "subQuestionFAQ": []
        },
        {
            "idFAQ": 10,
            "idPregunta": 8,
            "cabecera": false,
            "subCabecera": false,
            "datosColumna": "Selecciona la opción “Grupos”",
            "fechaCreo": new Date("2024-07-19T15:20:48.713"),
            "activo": true,
            "isCollapse": false,
            "subQuestionFAQ": []
        },
        {
            "idFAQ": 11,
            "idPregunta": 8,
            "cabecera": false,
            "subCabecera": false,
            "datosColumna": "Selecciona el grado que deseas comparar en la opción “Grados”",
            "fechaCreo": new Date("2024-07-19T15:20:48.717"),
            "activo": true,
            "isCollapse": false,
            "subQuestionFAQ": []
        },
        {
            "idFAQ": 12,
            "idPregunta": 8,
            "cabecera": false,
            "subCabecera": false,
            "datosColumna": "A continuación, la opción de grupos te mostrará los grupos registrados en ese grado, selecciona más de una opción para comparar.",
            "fechaCreo": new Date ("2024-07-19T15:20:48.72"),
            "activo": true,
            "isCollapse": false,
            "subQuestionFAQ": []
        },
        {
            "idFAQ": 13,
            "idPregunta": 8,
            "cabecera": false,
            "subCabecera": false,
            "datosColumna": "Presiona el botón azul “Filtrar” para mostrar los resultados.",
            "fechaCreo": new Date("2024-07-19T15:20:48.727"),
            "activo": true,
            "isCollapse": false,
            "subQuestionFAQ": []
        }
    ]
},
{
    "idFAQ": 14,
    "idPregunta": 14,
    "cabecera": true,
    "subCabecera": false,
    "datosColumna": "¿Cómo descargo actividades y estrategias de trabajo?",
    "fechaCreo": new Date("2024-07-19T15:30:32.657"),
    "activo": true,
    "isCollapse": false,
    "questionFAQ": [
        {
            "idFAQ": 15,
            "idPregunta": 14,
            "cabecera": false,
            "subCabecera": false,
            "datosColumna": "Dirígete a la barra de menú, a la izquierda de tu pantalla",
            "fechaCreo": new Date("2024-07-19T15:30:32.663"),
            "activo": true,
            "isCollapse": false,
            "subQuestionFAQ": []
        },
        {
            "idFAQ": 16,
            "idPregunta": 14,
            "cabecera": false,
            "subCabecera": false,
            "datosColumna": "Selecciona la opción “Grupos”",
            "fechaCreo": new Date("2024-07-19T15:30:32.667"),
            "activo": true,
            "isCollapse": false,
            "subQuestionFAQ": []
        },
        {
            "idFAQ": 17,
            "idPregunta": 14,
            "cabecera": true,
            "subCabecera": true,
            "datosColumna": "Para grados:",
            "fechaCreo": new Date ("2024-07-19T15:30:32.67"),
            "activo": true,
            "isCollapse": false,
            "subQuestionFAQ": [
                {
                    "idFAQ": 18,
                    "idPregunta": 17,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Selecciona el grado del que desees descargar recursos.",
                    "fechaCreo": new Date ("2024-07-19T15:30:58.39"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 19,
                    "idPregunta": 17,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Haz click en “Filtrar”",
                    "fechaCreo": new Date("2024-07-19T15:30:58.393"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 20,
                    "idPregunta": 17,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Dirígete a la sección de “Intervenciones”",
                    "fechaCreo": new Date("2024-07-19T15:30:58.397"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 21,
                    "idPregunta": 17,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Verás el menú de recursos del lado derecho.",
                    "fechaCreo": new Date("2024-07-19T15:30:58.4"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 22,
                    "idPregunta": 17,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Haz click en cualquiera de los tres recursos sugeridos",
                    "fechaCreo": new Date("2024-07-19T15:30:58.403"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 23,
                    "idPregunta": 17,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Verás una lista de recursos, presiona el botón azul a la derecha del nombre de cada uno para descargarlo.",
                    "fechaCreo": new Date("2024-07-19T15:30:58.407"),
                    "activo": true,
                    "isCollapse": false
                }
            ]
        },
        {
            "idFAQ": 24,
            "idPregunta": 14,
            "cabecera": true,
            "subCabecera": true,
            "datosColumna": "Para Grupos:",
            "fechaCreo": new Date ("2024-07-19T15:31:24.72"),
            "activo": true,
            "isCollapse": false,
            "subQuestionFAQ": [
                {
                    "idFAQ": 25,
                    "idPregunta": 24,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Selecciona el grado del que desees descargar recursos.",
                    "fechaCreo": new Date("2024-07-19T15:31:24.727"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 26,
                    "idPregunta": 24,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Selecciona el grupo específico",
                    "fechaCreo": new Date ("2024-07-19T15:31:24.73"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 27,
                    "idPregunta": 24,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Haz click en “Filtrar”",
                    "fechaCreo": new Date("2024-07-19T15:31:24.733"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 28,
                    "idPregunta": 24,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Dirígete a la sección de “Intervenciones”",
                    "fechaCreo": new Date("2024-07-19T15:31:24.737"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 29,
                    "idPregunta": 24,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Verás el menú de recursos del lado derecho.",
                    "fechaCreo": new Date ("2024-07-19T15:31:24.74"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 30,
                    "idPregunta": 24,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Haz click en cualquiera de los tres recursos sugeridos",
                    "fechaCreo": new Date("2024-07-19T15:31:24.743"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 31,
                    "idPregunta": 24,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Verás una lista de recursos, presiona el botón azul a la derecha del nombre de cada uno para descargarlo.",
                    "fechaCreo": new Date("2024-07-19T15:31:24.747"),
                    "activo": true,
                    "isCollapse": false
                }
            ]
        },
        {
            "idFAQ": 32,
            "idPregunta": 14,
            "cabecera": true,
            "subCabecera": true,
            "datosColumna": "Para estudiantes:",
            "fechaCreo": new Date("2024-07-19T15:35:12.003"),
            "activo": true,
            "isCollapse": false,
            "subQuestionFAQ": [
                {
                    "idFAQ": 33,
                    "idPregunta": 32,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Dirígete a la barra de menú, a la izquierda de tu pantalla",
                    "fechaCreo": new Date ("2024-07-19T15:35:12.01"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 34,
                    "idPregunta": 32,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Selecciona la opción “Estudiantes”",
                    "fechaCreo": new Date("2024-07-19T15:35:12.013"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 35,
                    "idPregunta": 32,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Presiona el botón “Buscar”",
                    "fechaCreo": new Date("2024-07-19T15:35:12.017"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 36,
                    "idPregunta": 32,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Dirígete al buscador y escribe el nombre del estudiante que deseas.",
                    "fechaCreo": new Date("2024-07-19T15:35:12.023"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 37,
                    "idPregunta": 32,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": " Selecciona el estudiante, esto te llevará a la pantalla de intervención individual del estudiante. ",
                    "fechaCreo": new Date("2024-07-19T15:35:12.027"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 38,
                    "idPregunta": 32,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Dirígete a la sección de “Intervenciones”",
                    "fechaCreo": new Date ("2024-07-19T15:35:12.03"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 39,
                    "idPregunta": 32,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Verás el menú de recursos del lado derecho.",
                    "fechaCreo": new Date("2024-07-19T15:35:12.037"),
                    "activo": true,
                    "isCollapse": false
                },
                {
                    "idFAQ": 40,
                    "idPregunta": 32,
                    "cabecera": false,
                    "subCabecera": true,
                    "datosColumna": "Haz click en cualquiera de los tres recursos sugeridos",
                    "fechaCreo": new Date ("2024-07-19T15:35:12.04"),
                    "activo": true,
                    "isCollapse": false
                }
            ]
        }
    ]
},
  ];
  questionFAQ: questionHelpFAQ[] = [];
  currentElement!: string;
  screenSmall = false;
  public show = true;

  @ViewChild('scrollOne') scrollOne!: ElementRef;
  @ViewChild('scrollTwo') scrollTwo!: ElementRef;
  @ViewChild('scrollThree') scrollThree!: ElementRef;
  @ViewChild('scrollFour') scrollFour!: ElementRef;
  @ViewChild('scrollFive') scrollFive!: ElementRef;

  @ViewChildren('scrollTwo') scrollTwos!: QueryList<ElementRef>;
  @ViewChildren('scrollThree') scrollThrees!: QueryList<ElementRef>;
  @ViewChildren('scrollFour') scrollFours!: QueryList<ElementRef>;
  @ViewChildren('scrollFive') scrollFives!: QueryList<ElementRef>;

  constructor(
  ) { }

  ngOnInit(): void {

  }



  openlink(id: any){
    let url = '';
    switch(id){
      case '1':
         url = 'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ15MBZT1hzPFp8u77K8QHjnxM57BuOtVxKT8YUleTsm88p1qQiewahXxYjg28ztV5bgsPZgCEAw?gv=true%27'; 
        break;
      case '2':
        url = 'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0Tr4DFyLzboVrjGyLzQk5GIqe_kSeptGW87cwHHavEMPPaWySkKufjwjy-5ITd-AbWOdtXb2Jg?gv=true%27';
        break;
      case '3':
        url = 'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1ucL-8PJLP6vz1JqEK_fQqfz4F1H2H0e_vh6Qfl0mL1Q_SRgpKBvMnn6naRWXwhqowPDpfdoJ2?gv=true%27';
        break;
      case '4':
        url = 'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0hAeBdPJzRTFmUdkcdeGV-RXCdrBEmFF3zipxLqDuIB4A-xS1UwVOXRTIaAvGpgiJZRknp1pPb?gv=true%27';
        break;
    }
    window.open(url, '_blank');
  }

  updateCurrentElement(element: 'scrollOne' | 'scrollTwo' | 'scrollThree') {
    this.currentElement = element;
  }

  updateVerticalScroll(event:any): void {
    if (this.currentElement === 'scrollTwo') {
      if(!this.screenSmall){
        this.scrollOne.nativeElement.scrollLeft = event.target.scrollLeft;
      }
      this.scrollTwos.forEach(scrollTwo => scrollTwo.nativeElement.scrollLeft = event.target.scrollLeft);
      this.scrollFives.forEach(scrollFive => scrollFive.nativeElement.scrollLeft = event.target.scrollLeft);

    } 
    else if (this.currentElement === 'scrollThree') {
      //this.scrollFour.nativeElement.scrollLeft = event.target.scrollLeft;
      this.scrollThrees.forEach(scrollThree => scrollThree.nativeElement.scrollLeft = event.target.scrollLeft);
      this.scrollFours.forEach(scrollFour => scrollFour.nativeElement.scrollLeft = event.target.scrollLeft);

    }
  }


  toRomanNumerals(num: number): string {
    const romanNumerals: any[] = [
      { value: 10, numeral: 'X' },
      { value: 9, numeral: 'IX' },
      { value: 5, numeral: 'V' },
      { value: 4, numeral: 'IV' },
      { value: 1, numeral: 'I' }
    ];

    let result = '';
    for (let i = 0; i < romanNumerals.length; i++) {
      while (num >= romanNumerals[i].value) {
        result += romanNumerals[i].numeral;
        num -= romanNumerals[i].value;
      }
    }
    return result;
  }

  
  handleCollapse = (item: any) => {
    item.isCollapse = !item.isCollapse;
  };

}
